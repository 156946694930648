import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
	BrowserRouter as Router,
	Link,
	useLocation
} from "react-router-dom";

import {Signage} from './pages/Signage'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Signage />
        </Router>
      </header>
    </div>
  );
}

export default App;
