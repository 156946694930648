import React, { useState } from "react";
import {
	useLocation
} from "react-router-dom";

import axios from 'axios';
import useInterval from 'use-interval';
import moment from 'moment'
moment.locale('ja');

interface IDRStatus {
  status: string;
  text: string;
  fileName: string;
}
export const Signage = (props: any) => {

	const [state, setState] = useState<IDRStatus>();
	const [time, setTime] = useState<String>();
	const [error, setError]: [string, (error: string) => void] = React.useState(
		''
	);
	const { search } = useLocation();

	function useQuery() {

		return React.useMemo(() => new URLSearchParams(search), [search]);
	}
	let query = useQuery();
	const fetchFunc = () => {
		axios.get<IDRStatus>(`${process.env.REACT_APP_API_SERVER_URL}/${query.get("area")}?key=${query.get("key")}`, {
			timeout: 10000,
		})
			.then((response) => {
				localStorage.setItem('previousState', localStorage.getItem('currentState') || '0');
				localStorage.setItem('currentState', response.data.status);

				setState(response.data);
			})
			.catch((ex) => {
				let error = ex.code === 'ECONNABORTED'
					? 'A timeout has occurred'
					: ex.response.status === 404
						? 'Resource Not Found'
						: 'An unexpected error has occurred';
				setError(error);
			});
	}
	const calcRemainTime = (targetDate: string) => {
		// ミリ秒からdurationオブジェクトを生成
		const duration = calcRemainTimeRaw(targetDate);

		const days = Math.floor(duration.asDays());
		const hours = duration.hours();
		const minutes = duration.minutes();
		const seconds = duration.seconds();
		const remainTime = days == 0 ? `${hours}時間${minutes}分${seconds}秒` : `${days}日と${hours}時間${minutes}分${seconds}秒`;
		return remainTime;
	}
	const calcRemainTimeRaw = (targetDate: string) => {
		// ミリ秒からdurationオブジェクトを生成
		return moment.duration(moment(targetDate.split('~')[0]).diff(moment()));
	}
	const displayDateTime = () => {
		const today = new Date();
		const currentDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
		setTime(currentDate);
	}

	useInterval(() => {
		const _ = fetchFunc();
	}, 10000);
	useInterval(() => {
		const _ = displayDateTime();
	}, 10000);

	if (state?.status === 'received') {

		// 5分前にもう一度
		const duration = calcRemainTimeRaw(state?.text.split('\n')[0])

		return <div style={{ textAlign: "center", width: "100vw", height: "100vh", backgroundColor: "red", display: "flex", alignItems: "center", justifyContent: "center", flexFlow: "column"}}>
			<div style={{ position: "absolute", left: "0", top: "0", marginLeft: "3rem", marginTop: "2rem"}}>スマートエナジー熊本管理RAシステム</div>
			<div style={{ position: "absolute", right: "0", bottom: "0", margin: ""}}>
				<audio
					loop
					controls
					autoPlay
					src={duration.hours() === 0 && duration.minutes() < 5 ? 'https://vppjfe.blob.core.windows.net/files/5minutes.mp3' : state.fileName}>
					Your browser does not support the
					<code>audio</code> element.
				</audio>
			</div>
			<br />
			<div style={{ fontSize: "xxx-large"}}>DR発動指令あり</div><br/>
			<div style={{ fontSize: "xxx-large", paddingBottom: "60px"}}>開始まで {calcRemainTime(state?.text.split('\n')[0])}</div>
			<div style={{ position: "absolute", top: "70%", left:"25%"}}>指令期間: {state?.text.split('\n')[0]}</div>
			<div style={{ position: "absolute", top: "75%", left:"25%"}}>指令容量: {state?.text.split('\n')[1]}</div>
			<div style={{ position: "absolute", top: "80%", left:"25%"}}>緊急連絡先: {state?.text.split('\n')[2]}</div>
			<div style={{ position: "absolute", top: "85%", fontSize: "medium"}}>(上記指令で問題がある場合、緊急連絡先に至急連絡ください。)</div>
			<div style={{ position: "absolute", left: "0", bottom: "0", marginLeft: "3rem", marginBottom: "2rem"}}>{time}</div>
			{error && <p className="error">{error}</p>}
		</div>;
	}
	else if (state?.status === 'running'){
		return <div style={{ textAlign: "center", width: "100vw", height: "100vh", backgroundColor: "orange", display: "flex", alignItems: "center", justifyContent: "center", flexFlow: "column"}}>
			<div style={{ position: "absolute", left: "0", top: "0", marginLeft: "3rem", marginTop: "2rem"}}>スマートエナジー熊本管理RAシステム</div>
			<div style={{ position: "absolute", right: "0", bottom: "0", margin: ""}}>
				<audio
					loop
					controls
					autoPlay
					src='https://vppjfe.blob.core.windows.net/files/start2.wav'>
					Your browser does not support the
					<code>audio</code> element.
				</audio>
			</div>
			<div style={{ fontSize: "xxx-large",}}>DR発動指令中</div><br/>
			<div style={{ position: "absolute", top: "70%", left:"25%"}}>指令期間: {state?.text.split('\n')[0]}</div>
			<div style={{ position: "absolute", top: "75%", left:"25%"}}>指令容量: {state?.text.split('\n')[1]}</div>
			<div style={{ position: "absolute", top: "80%", left:"25%"}}>緊急連絡先: {state?.text.split('\n')[2]}</div>
			<div style={{ position: "absolute", left: "0", bottom: "0", marginLeft: "3rem", marginBottom: "2rem"}}>{time}</div>
			{error && <p className="error">{error}</p>}
		</div>;
	} else {
		return (
			<div style={{ textAlign: "center", width: "100vw", height: "100vh", backgroundColor: "blue", display: "flex", alignItems: "center", justifyContent: "center", flexFlow: "column"}}>
				<div style={{ position: "absolute", left: "0", top: "0", marginLeft: "3rem", marginTop: "2rem"}}>スマートエナジー熊本管理RAシステム</div>
				{localStorage.getItem('previousState') === 'running' && localStorage.getItem('currentState') === 'normal'  && <div style={{ position: "absolute", right: "0", bottom: "0", margin: ""}}>
					<audio
						controls
						autoPlay
						src="https://vppjfe.blob.core.windows.net/files/end.mp3">
						Your browser does not support the
						<code>audio</code> element.
					</audio>
				</div>}
				<div style={{ fontSize: "xxx-large",}}>DR発動指令外</div><br/>
				<div style={{ position: "absolute", left: "0", bottom: "0", marginLeft: "3rem", marginBottom: "2rem"}}>{time}</div>
				{error && <p className="error">{error}</p>}
			</div>
		);
	}
};

export default Signage;